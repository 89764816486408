/*!
 * JavaScript for Blog
 * Give as you Live Ltd
 */

// Import SASS/SCSS styles
import 'Sass/blog-gayl.scss';

// Import common modules
const DropdownMenu              = require('Scripts/common/nav-dropdown');
const Global                    = require('Scripts/common/global');
const Loader                    = require('Scripts/common/loader');
const MobileMenu                = require('Scripts/common/nav-mobile');
const OwlCarousel               = require('Scripts/common/owl-carousel');
const ShowHide                  = require('Scripts/common/showhide');

// Define groups of modules - the group name is set in your template ($jsModuleGroup).
// This is just a nicer way to load JS on specific pages without looking at the path/params
// To initiate a group, use the #module macro. E.g. #module("my-group") would add 'my-group' from the groups below.
const moduleGroups = {
	// Available on every page
	'global': [Global, DropdownMenu, MobileMenu, OwlCarousel, ShowHide]
};

Loader.load(moduleGroups);